import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { RestaurantListMarker, Text } from 'components/atoms';
import { FavoriteRestaurantButton } from 'components/molecules';
import { buildAboutRestaurantURL } from 'utils/url';
import { history } from 'utils/router';
import { find } from 'lodash';
import { hasProp } from 'utils/object';

class RestaurantListItemNew extends Component {
	constructor(props) {
		super(props);

		const { isSV, isMV } = props;

		this.state = {
			toggled: false,
			itemHeight: isSV ? 40 : isMV ? 28 : 41
		};
	}

	componentDidUpdate = prevProps => {
		this.setListItemHeight(prevProps);
	};

	setListItemHeight = prevProps => {
		if (prevProps.isSV !== this.props.isSV) {
			if (this.props.isSV) {
				this.setState({ itemHeight: 40 });
			}
		}

		if (prevProps.isMV !== this.props.isMV) {
			if (this.props.isMV) {
				this.setState({ itemHeight: 28 });
			}
		}

		if (prevProps.isLV !== this.props.isLV) {
			if (this.props.isLV) {
				this.setState({ itemHeight: 41 });
			}
		}
	};

	toggleSection = e => {
		e.stopPropagation();
		this.setState({ toggled: !this.state.toggled });
	};

	selectPin = async pin => {
		this.props.setActivePin(pin);
		await this.props.setRecenterToMapMarker(true);

		if (this.props.results && this.props.results.length > 0) {
			let result = find(this.props.results, { id: pin });
			await this.props.setCenter({ lat: result.location.lat, lng: result.location.lon });
		}
	};

	goToDetails = restaurant => {
		if (this.props.detailsInNewTab) {
			window.open(buildAboutRestaurantURL(restaurant), '_blank');
		} else {
			this.props.saveSearchState();
			// console.log('loading restaurant: ', restaurant);
			history.push(buildAboutRestaurantURL(restaurant));
			document.title = `Dinova - Search`;
		}
	};

	cuisines = () => {
		const restaurant = this.props.item[0];
		let { cuisine, menuSpecialties } = restaurant;
		if (!cuisine) {
			cuisine = '';
		} else {
			cuisine = cuisine.name;
		}

		let specialties = new Set();
		specialties.add(cuisine);
		if (menuSpecialties) {
			let i = 0;
			while (i < 5 && menuSpecialties[i]) {
				specialties.add(menuSpecialties[i].name);
				i++;
			}
		}

		let specialtiesString = Array.from(specialties).join(', ');
		return specialtiesString;
	};

	render() {
		if (this.props.item && !this.props.item.length) {
			return null;
		}

		let {
				activePin,
				hideMapPin,
				IMAGE_BASE_URL,
				isSV,
				showFavoriteIcon,
				translate,
				onItemClick,
				OnItemClickComponent,
				getIsItemSelected,
				listItemClass
			} = this.props,
			restaurant = this.props.item[0],
			distance = restaurant.distance?.span || restaurant.distance || null,
			unit = restaurant.distance?.units || (restaurant.distance && 'mi') || null,
			numberOfLocations = this.props.item.length,
			otherRestaurants = this.props.item.slice(1),
			{ images } = restaurant,
			{ toggled } = this.state,
			additionalInfoClass = classnames('additional-info', isSV && 'no-marker'),
			multipleLocationsClass = classnames('another-restaurant-item', isSV && 'no-marker', toggled && 'toggled'),
			locationsToggleClass = classnames('locations', toggled && 'open');

		const isActive = restaurant.id === activePin;

		return (
			<div
				className={classnames('restaurant-wrapper', listItemClass, {
					active: getIsItemSelected && Boolean(getIsItemSelected(restaurant))
				})}
				data-cy={isActive ? 'restaurant-list-item-active' : 'restaurant-list-item'}
			>
				<a className="restaurant-link" onClick={this.goToDetails.bind(this, restaurant)}>
					<article id={'-----------id'} className="restaurant-item">
						<div
							className={'restaurant-img-wrapper'}
							style={{ backgroundImage: `url("${images.main}?w=.4")` }}
						>
							{restaurant.leaving && <Text size="sm">Lorem.. Leaving Soon</Text>}
						</div>
						{onItemClick && OnItemClickComponent && (
							<OnItemClickComponent
								item={restaurant}
								onClick={onItemClick}
								getIsItemSelected={getIsItemSelected}
							/>
						)}
						{!isSV && !hideMapPin && (
							<div className="restaurant-marker-wrapper">
								<RestaurantListMarker
									markerId={restaurant.id}
									activePin={activePin}
									onClick={this.selectPin.bind(this, restaurant.id)}
								/>
							</div>
						)}
						{showFavoriteIcon && (
							<div className={classnames('restaurant-marker-wrapper', { ['left']: !hideMapPin })}>
								<FavoriteRestaurantButton id={restaurant.id} color="red" restaurant={restaurant} />
							</div>
						)}
						<div className="footer">
							<div className="info">
								<p
									className="restaurant-name"
									data-cy={
										isActive ? 'restaurant-list-item-title-active' : 'restaurant-list-item-title'
									}
								>
									{restaurant.name}
								</p>
								{hideMapPin && (
									<div className="d-flex">
										<p className="address">
											{restaurant.address.street.join('\n')} {restaurant.address.city},{' '}
											{restaurant.address?.state?.abbreviation || ''}
										</p>
									</div>
								)}
								<div className="d-flex">
									<p className="cuisine-name">{this.cuisines()}</p>
								</div>
							</div>
							{((distance && unit) || numberOfLocations > 1) && (
								<div className={additionalInfoClass}>
									{distance && unit && (
										<span className="distance">
											{parseFloat(distance).toFixed(1) + ' '}
											{translate(`Units.${unit}`)}
										</span>
									)}
									{numberOfLocations > 1 && (
										<span
											onClick={this.toggleSection}
											className={locationsToggleClass}
											data-cy="locations-toggle"
										>
											<span className="locations-inner">
												<span className="locations-number">{numberOfLocations} Locations</span>
												<span className="caret">
													<img
														className="img-fluid"
														src={IMAGE_BASE_URL + '/icons/chevron.svg'}
														alt={
															toggled
																? 'hide other restaurants'
																: 'show other restaurants'
														}
													/>
												</span>
											</span>
										</span>
									)}
								</div>
							)}
						</div>
					</article>
				</a>
				{/* TODO create another component for sub-items */}
				<div
					style={{
						height: otherRestaurants.length * this.state.itemHeight + 'px'
					}} /* 39px = styled height of item */
					className={`other-restaurant-links-wrapper ${toggled ? 'toggled' : 'closed'}`}
				>
					{otherRestaurants.map(item => {
						let restaurantData = {
							id: item.id,
							name: item.name,
							city: item.address.city,
							state: hasProp(item.address, 'state.abbreviation') ? item.address.state.abbreviation : '',
							...(item.distance ? { distance: { ...item.distance } } : {})
						};

						return (
							<a
								key={item.id}
								className="another-restaurant-link"
								onClick={this.goToDetails.bind(this, restaurantData)}
							>
								<article id={item.id} className={multipleLocationsClass}>
									<h2 className="restaurant-name">{item.name}</h2>
									{item.distance && item.distance?.units && (
										<span className="distance">
											{parseFloat(item.distance?.span).toFixed(1)} {item.distance?.units}
										</span>
									)}
									{showFavoriteIcon && (
										<FavoriteRestaurantButton
											id={item.id}
											color="red"
											restaurant={item}
											additionalLocation={true}
										/>
									)}
									{!isSV && !hideMapPin && (
										<div>
											<RestaurantListMarker
												markerId={item.id}
												activePin={activePin}
												onClick={this.selectPin.bind(this, item.id)}
											/>
										</div>
									)}

									{hideMapPin && <p className="list-address">{item.address.street.join('\n')}</p>}
								</article>
							</a>
						);
					})}
				</div>
			</div>
		);
	}
}

RestaurantListItemNew.defaultProps = {
	item: []
};

RestaurantListItemNew.propTypes = {
	activePin: PropTypes.string,
	hideMapPin: PropTypes.bool,
	IMAGE_BASE_URL: PropTypes.string.isRequired,
	isSV: PropTypes.bool.isRequired,
	isMV: PropTypes.bool.isRequired,
	isLV: PropTypes.bool.isRequired,
	onItemClick: PropTypes.func,
	OnItemClickComponent: PropTypes.node,
	getIsItemSelected: PropTypes.func,
	saveSearchState: PropTypes.func.isRequired,
	setActivePin: PropTypes.func.isRequired,
	setCenter: PropTypes.func.isRequired,
	setRecenterToMapMarker: PropTypes.func.isRequired,
	results: PropTypes.array,
	showFavoriteIcon: PropTypes.bool,
	translate: PropTypes.func.isRequired,
	item: PropTypes.array,
	listItemClass: PropTypes.string,
	detailsInNewTab: PropTypes.bool
};

export default RestaurantListItemNew;
