import React, { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text } from 'components/atoms';
import { useTranslate } from 'hooks';

const ListPromo = ({ ariaHidden, className, hideImages = false, imageBaseUrl, translateKey }) => {
	const translate = useTranslate();
	const wrapperRef = createRef();
	const contentRef = createRef();

	// componentDidMount
	useEffect(() => {
		if (hideImages) {
			const wrapperHeight = wrapperRef.current.scrollHeight;
			wrapperRef.current.style.height = wrapperHeight * 1.25 + 'px';
			wrapperRef.current.style.width = wrapperHeight * 1.25 + 'px';
			wrapperRef.current.style.top = (wrapperHeight / 1.7) * -1 + 'px';
			wrapperRef.current.style.right = (wrapperHeight / 3) * -1 + 'px';

			contentRef.current.style.paddingTop = wrapperHeight / 1.5 + 'px';
			contentRef.current.style.paddingLeft = wrapperHeight / 7 + 'px';
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	const classProps = classnames('promo-banner list', className && className, !hideImages && 'image');

	const count = translate(`${translateKey}.promo.count`);
	const listItems = [];

	for (let i = 0; i < count; i++) {
		const hasImage = translate(`${translateKey}.promo.items.${i}.image`).indexOf('Missing') === -1;

		listItems.push(
			<li key={`${Math.random()}-${i}`}>
				<Text size="xs">{translate(`${translateKey}.promo.items.${i}.text`)}</Text>
				{!hideImages && hasImage && (
					<div className="promo-image-wrapper">
						<img
							src={`${imageBaseUrl}/${translate(`${translateKey}.promo.items.${i}.image`)}`}
							alt={translate(`${translateKey}.promo.items.${i}.imageAlt`)}
						/>
					</div>
				)}
			</li>
		);
	}

	return (
		<div className={classProps} ref={wrapperRef} aria-hidden={ariaHidden} tabIndex={ariaHidden ? -1 : undefined}>
			<div className="promo-banner-content" ref={contentRef}>
				<p className="promo-header">{translate(`${translateKey}.promo.title`)}</p>
				<ul>{listItems}</ul>
			</div>
		</div>
	);
};

ListPromo.propTypes = {
	ariaHidden: PropTypes.bool,
	className: PropTypes.string,
	hideImages: PropTypes.bool,
	imageBaseUrl: PropTypes.string,
	translateKey: PropTypes.string.isRequired
};

export default ListPromo;
