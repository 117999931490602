import * as t from './types';

export const toggleShowFilter = show => ({
	type: t.TOGGLE_SHOW_FILTER,
	payload: show
});

export const toggleShowList = show => {
	window.localStorage.setItem('search-page-show-list', show);
	return {
		type: t.TOGGLE_SHOW_LIST,
		payload: show
	};
};

export const setActivePin = pin => ({
	type: t.SET_ACTIVE_PIN,
	payload: pin
});

export const setMapCardDisplayed = isDisplayed => ({
	type: t.SET_MAP_CARD_DISPLAYED,
	payload: isDisplayed
});

export const setSearchErrorType = errorType => ({
	type: t.SET_SEARCH_PAGE_ERROR_TYPE,
	payload: errorType
});

export const saveSearchState = () => ({
	type: t.SAVE_SEARCH_PAGE_STATE
});

export const hydrateFromSavedSearchState = () => ({
	type: t.HYDRATE_FROM_SEARCH_PAGE_SAVE_STATE
});

export const setLastSearch = href => ({
	type: t.SET_LAST_BUSINESS_SEARCH,
	payload: href
});
