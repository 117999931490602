import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';
import { isLV, isSV } from 'utils/sizes';
import useAmplitudeContext from 'context/AmplitudeContext';

/**
 * List of links that provide access to a restaurant's avialble CTAs. Ex: 'Call', 'Website', 'Order'.
 * @param {CTAMenuProps} props
 */

const CTAMenu = ({ actions, className, isLV, isSV, translate }) => {
	const { IosWebAmpli } = useAmplitudeContext();
	const classProps = classnames('restaurant-cta-menu', className);

	const handleOnClick = action => {
		console.log(action);
		switch (action) {
			case translate('Details.actions.website'):
				IosWebAmpli.websiteLinkTapped();
				break;
			case translate('Details.actions.reservations'):
				IosWebAmpli.restaurantOrderOnlineNowTapped();
				break;
			case translate('Details.actions.catering'):
				IosWebAmpli.restaurantOrderOnlineNowTapped();
				break;
			case translate('Details.actions.ordering'):
				IosWebAmpli.restaurantOrderOnlineNowTapped();
				break;
			case translate('Details.actions.directions'):
				IosWebAmpli.restaurantDirectionsTapped();
				break;
			default:
				IosWebAmpli.restaurantOrderOnlineNowTapped();
				break;
		}
	};

	return (
		<div className={classProps}>
			{actions.map((action, i) => {
				const title = isLV ? action.titleDesktop : action.titleMobile;
				if (isSV && title === 'Order') {
					return null;
				}

				if (isLV && title === 'Directions') {
					return null;
				}

				return (
					<a
						className={classnames('cta-btn align-center', isLV ? 'flex' : 'flex-col')}
						href={action.url}
						target="_blank"
						rel="noopener noreferrer"
						key={`restaurant-cta-menu-action-${i}`}
						data-cy={title}
						onClick={() => handleOnClick(title)}
					>
						<img src={isLV ? action.icon : action.mobileIcon ? action.mobileIcon : action.icon} alt="" />
						{title}
					</a>
				);
			})}
		</div>
	);
};

CTAMenu.defaultProps = {
	actions: [],
	className: ''
};

/**
 * Action used in {@link CTAMenuProps} and {@link CTAMenu}
 * @interface IAction
 * @property {string} title The full text of the action title.
 * @property {string} titleShort A shorter version of the action title.
 * @property {string} url The url to go to when this action is initiated.
 * @property {string} icon The icon to show on the actions CTA button.
 */

/**
 * {@link CTAMenu} Props
 * @interface Props_CTAMenu
 * @property {Action[]} actions An array of Actions.
 * @property {string} className Additional className to apply to the {@link CTAMenu} wrapper.
 */
CTAMenu.propTypes = {
	// Optional
	actions: PropTypes.arrayOf(
		PropTypes.shape({
			titleShort: PropTypes.string,
			title: PropTypes.string,
			url: PropTypes.string,
			icon: PropTypes.string
		})
	),
	className: PropTypes.string,
	isLV: PropTypes.bool.isRequired,
	isSV: PropTypes.bool.isRequired,
	translate: PropTypes.func
};

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes),
	isLV: isLV(sizes)
});

export default withSizes(mapSizesToProps)(CTAMenu);
