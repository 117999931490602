import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useAmplitudeState } from 'hooks';

const useAmplitudeContextValue = (/*:AmplitudeContextProps*/) => {
	const amplitudeState = useAmplitudeState();

	return amplitudeState;
};

const AmplitudeContext = createContext({});

const AmplitudeContextProvider = ({ children }) => {
	const value = useAmplitudeContextValue();

	return <AmplitudeContext.Provider value={value}>{children}</AmplitudeContext.Provider>;
};

AmplitudeContextProvider.propTypes = {
	children: PropTypes.node
};

const useAmplitudeContext = () => {
	const context = useContext(AmplitudeContext);

	return context;
};

const AmplitudeContextConsumer = AmplitudeContext.Consumer;
export { AmplitudeContextConsumer, AmplitudeContextProvider };
export default useAmplitudeContext;
