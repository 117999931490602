/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 8
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/dinova/default/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/dinova/default/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [configuration]
 * @property {BrowserClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'default'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} default
 */
export const ApiKey = {
  default: 'd3dd43551162a1d474f2ac52c4cdf03'
};

/**
 * @typedef {Object} RestaurantDetails
 * @param {string} restaurant_address address of restaurant
 * @param {string} restaurant_brand Property has no description in tracking plan.
 * @param {'map'|'list'|'other'} restaurant_entry_point origin of restaurant detail view (map, list, or other)
 */

/**
 * @typedef {Object} SearchBasics
 * @param {boolean} search_current_location specifies if search was performed with some type of "Near Current Location" used (ie, user did not provide a custom location)
 * @param {string} [search_keywords] keywords used for restaurant search
 * @param {string} search_location_name value in the location field (if not a search near current location search type)
 * @param {string[]} [search_restaurants_displayed] list the first ten restaurant brands displayed by brand name  -> this is to provide some loose attribution for restaurant purchase
 */

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration = {
  plan: {
    version: '8',
    branch: 'main',
    source: 'web',
    versionId: '469c4ad4-742d-4486-9a33-9e5192dfb9d5'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-javascript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export class Identify {
  constructor(properties) {
    this.event_type = amplitude.Types.SpecialEventType.IDENTIFY;
    this.event_properties = properties;
  }
}

export class HomePageViewed {
  constructor() {
    this.event_type = 'Home Page Viewed';
  }
}

export class RegisterStep1Completed {
  constructor() {
    this.event_type = 'Register Step 1 Completed';
  }
}

export class RegistrationCompleted {
  constructor(properties) {
    this.event_type = 'Registration Completed';
    this.event_properties = properties;
  }
}

export class RegistrationScreenViewed {
  constructor(properties) {
    this.event_type = 'Registration Screen Viewed';
    this.event_properties = properties;
  }
}

export class RestaurantCallTapped {
  constructor() {
    this.event_type = 'Restaurant Call Tapped';
  }
}

export class RestaurantDetailsViewed {
  constructor(properties) {
    this.event_type = 'Restaurant Details Viewed';
    this.event_properties = properties;
  }
}

export class RestaurantDirectionsTapped {
  constructor(properties) {
    this.event_type = 'Restaurant Directions Tapped';
    this.event_properties = properties;
  }
}

export class RestaurantOrderOnlineNowTapped {
  constructor(properties) {
    this.event_type = 'Restaurant Order Online Now Tapped';
    this.event_properties = properties;
  }
}

export class SearchCompleted {
  constructor(properties) {
    this.event_type = 'Search Completed';
    this.event_properties = properties;
  }
}

export class WebsiteLinkTapped {
  constructor(properties) {
    this.event_type = 'Website Link Tapped';
    this.event_properties = properties;
  }
}

/**
 * @typedef PromiseResult
 * @type {object}
 * @property {Promise|undefined} promise
 */

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {BrowserClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
  }

  /**
   * @return {BrowserClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param {LoadOptions} options Configuration options to initialize the Ampli SDK with. 'environment', 'client.apiKey' or 'client.instance' is required.
   *
   * @return {PromiseResult}
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey;
    if (options?.client?.apiKey) {
      apiKey = options.client.apiKey;
    } else if (options?.environment) {
      apiKey = ApiKey[options.environment];
    }

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options?.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set or update that user's properties.
   *
   * @param {string|undefined} userId The user's id.
   * @param {Object} [properties] The user's properties.
   * @param {string} [properties.[AppsFlyer] app version] Property has no description in tracking plan.
   * @param {string} [properties.[AppsFlyer] appsflyer id] Property has no description in tracking plan.
   * @param {string} [properties.[AppsFlyer] campaign] Property has no description in tracking plan.
   * @param {string} [properties.[AppsFlyer] installed at] Property has no description in tracking plan.
   * @param {string} [properties.[AppsFlyer] media source] Property has no description in tracking plan.
   * @param {string} [properties.[AppsFlyer] network name] Property has no description in tracking plan.
   * @param {string} [properties.bigPrizeCampaignCode] Property has no description in tracking plan.
   * @param {string} [properties.companyCode] Property has no description in tracking plan.
   * @param {string} [properties.companyName] Property has no description in tracking plan.
   * @param {string} [properties.email] Property has no description in tracking plan.
   * @param {boolean} [properties.highValueDiner] Property has no description in tracking plan.
   * @param {string} [properties.id] Property has no description in tracking plan.
   * @param {string} [properties.initial_dclid] Property has no description in tracking plan.
   * @param {string} [properties.initial_fbclid] Property has no description in tracking plan.
   * @param {string} [properties.initial_gbraid] Property has no description in tracking plan.
   * @param {string} [properties.initial_gclid] Property has no description in tracking plan.
   * @param {string} [properties.initial_ko_click_id] Property has no description in tracking plan.
   * @param {string} [properties.initial_li_fat_id] Property has no description in tracking plan.
   * @param {string} [properties.initial_msclkid] Property has no description in tracking plan.
   * @param {string} [properties.initial_referrer] Property has no description in tracking plan.
   * @param {string} [properties.initial_referring_domain] Property has no description in tracking plan.
   * @param {string} [properties.initial_rtd_cid] Property has no description in tracking plan.
   * @param {string} [properties.initial_ttclid] Property has no description in tracking plan.
   * @param {string} [properties.initial_twclid] Property has no description in tracking plan.
   * @param {string} [properties.initial_utm_campaign] Property has no description in tracking plan.
   * @param {string} [properties.initial_utm_content] Property has no description in tracking plan.
   * @param {string} [properties.initial_utm_id] Property has no description in tracking plan.
   * @param {string} [properties.initial_utm_medium] Property has no description in tracking plan.
   * @param {string} [properties.initial_utm_source] Property has no description in tracking plan.
   * @param {string} [properties.initial_utm_term] Property has no description in tracking plan.
   * @param {string} [properties.initial_wbraid] Property has no description in tracking plan.
   * @param {string} [properties.meetingPlanner] Property has no description in tracking plan.
   * @param {string} [properties.name] Property has no description in tracking plan.
   * @param {boolean} [properties.onboardingStatusComplete] Property has no description in tracking plan.
   * @param {number} [properties.points_available] the amount of points a user has. used to evaluate whether or not the experience is successful at getting them to redeem
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {string} [properties.referring_domain] Property has no description in tracking plan.
   * @param {boolean} [properties.traveler] Property has no description in tracking plan.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  identify(userId, properties, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options, user_id: userId};
    }

    const ampIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        ampIdentify.set(key, value);
      }
    }
    return this.amplitude.identify(ampIdentify, options);
  }

  /**
  * Flush the event.
  */
  flush() {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.flush();
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  track(event, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.track(event, undefined, options);
  }

  /**
   * Home Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/default/events/main/latest/Home%20Page%20Viewed)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  homePageViewed(options) {
    return this.track(new HomePageViewed(), options);
  }

  /**
   * Register Step 1 Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/default/events/main/latest/Register%20Step%201%20Completed)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  registerStep1Completed(options) {
    return this.track(new RegisterStep1Completed(), options);
  }

  /**
   * Registration Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/default/events/main/latest/Registration%20Completed)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.[Amplitude] Session Replay ID] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  registrationCompleted(properties, options) {
    return this.track(new RegistrationCompleted(properties), options);
  }

  /**
   * Registration Screen Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/default/events/main/latest/Registration%20Screen%20Viewed)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.[Amplitude] Session Replay ID] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  registrationScreenViewed(properties, options) {
    return this.track(new RegistrationScreenViewed(properties), options);
  }

  /**
   * Restaurant Call Tapped
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/default/events/main/latest/Restaurant%20Call%20Tapped)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  restaurantCallTapped(options) {
    return this.track(new RestaurantCallTapped(), options);
  }

  /**
   * Restaurant Details Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/default/events/main/latest/Restaurant%20Details%20Viewed)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.[Amplitude] Session Replay ID] Property has no description in tracking plan.
   * @param {string} properties.restaurant_address address of restaurant
   * @param {string} properties.restaurant_brand Property has no description in tracking plan.
   * @param {'map'|'list'|'other'} properties.restaurant_entry_point origin of restaurant detail view (map, list, or other)
   * @param {EventOptions} [options] Options for this track call.
   */
  restaurantDetailsViewed(properties, options) {
    return this.track(new RestaurantDetailsViewed(properties), options);
  }

  /**
   * Restaurant Directions Tapped
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/default/events/main/latest/Restaurant%20Directions%20Tapped)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.[Amplitude] Session Replay ID] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  restaurantDirectionsTapped(properties, options) {
    return this.track(new RestaurantDirectionsTapped(properties), options);
  }

  /**
   * Restaurant Order Online Now Tapped
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/default/events/main/latest/Restaurant%20Order%20Online%20Now%20Tapped)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.[Amplitude] Session Replay ID] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  restaurantOrderOnlineNowTapped(properties, options) {
    return this.track(new RestaurantOrderOnlineNowTapped(properties), options);
  }

  /**
   * Search Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/default/events/main/latest/Search%20Completed)
   *
   * contains all properties for search parameters. only required property is location
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.[Amplitude] Session Replay ID] Property has no description in tracking plan.
   * @param {boolean} properties.search_current_location specifies if search was performed with some type of "Near Current Location" used (ie, user did not provide a custom location)
   * @param {string} [properties.search_keywords] keywords used for restaurant search
   * @param {string} properties.search_location_name value in the location field (if not a search near current location search type)
   * @param {string[]} [properties.search_restaurants_displayed] list the first ten restaurant brands displayed by brand name  -> this is to provide some loose attribution for restaurant purchase
   * @param {EventOptions} [options] Options for this track call.
   */
  searchCompleted(properties, options) {
    return this.track(new SearchCompleted(properties), options);
  }

  /**
   * Website Link Tapped
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/default/events/main/latest/Website%20Link%20Tapped)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.[Amplitude] Session Replay ID] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  websiteLinkTapped(properties, options) {
    return this.track(new WebsiteLinkTapped(properties), options);
  }
}

export const ampli = new Ampli();
