import { useQuery } from './';
import axios from 'axios';
import { basePath_restaurant, AUTH_REQUEST_HEADER } from 'actions/utils';
import Cookies from 'universal-cookie';
import onboardingData from 'utils/onboardingData';

// interface useRestaurantDetailsQueryProps {}

export const RESTAURANT_SEARCH_QUERY = 'restaurant_search_query';
const cookies = new Cookies();

const useRestaurantSearchQuery = (props /*:useRestaurantDetailsQueryProps*/) => {
	return useQuery({
		enabled: Boolean(props?.searchParams?.mock || (props?.searchParams?.lat && props?.searchParams?.lng)),
		queryKey: [props.queryKey || RESTAURANT_SEARCH_QUERY, props],
		quietLoading: true,
		onSuccess: props.onSearchSuccess ? props.onSearchSuccess.bind(null, props.searchParams) : undefined,
		// reduxLoadingMsg: 'Searching Restaurants...',
		queryFn: async ({ queryKey }) => {
			const {
				searchParams: { mock, radius = '20', keywords, lat, lng, uom = 'mi' }
			} = queryKey[1];

			if (mock) {
				return new Promise(resolve => resolve(onboardingData.restaurants));
			} else {
				var url = `${basePath_restaurant()}restaurants/search?location=${lat},${lng}&uom=${uom}`;

				if (keywords && keywords.length > 0) {
					url += `&keywords=${keywords}`;
				}

				if (radius && radius.length > 0) {
					url += `&radius=${radius}`;
				}

				if (cookies.get('mceasy')) {
					url += '&network=mceasy';
				}
				if (cookies.get('visaedge')) {
					url += '&network=visaedge';
				}

				return axios.get(url, AUTH_REQUEST_HEADER()).then(res => res.data.restaurants);
			}
		}
	});
};

export default useRestaurantSearchQuery;
