import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useIsProdHost from './useIsProdHost';
// import queryString from 'query-string';

const useAppStoreDownloadLink = () => {
	const location = useLocation();
	const { host, isProdHost } = useIsProdHost();
	// const shortLink = queryString.parse(window.location.search).shortlink;
	const { isDownloadPath, isWeMovedPath, isSubscribePath, isAutoEnrollPath } = useMemo(() => {
		return {
			isDownloadPath:
				(location.pathname.includes('download') && !location.pathname.includes('users/register/download')) ||
				false,
			isWeMovedPath: location.pathname.includes('we-moved') || false,
			isSubscribePath:
				(location.pathname.includes('users/register') &&
					!location.pathname.includes('users/register/download')) ||
				false,
			isAutoEnrollPath: location.pathname.includes('users/register/download') || false
		};
	}, [location.pathname]);

	const downloadLink = useMemo(() => {
		if (isDownloadPath) {
			return 'https://itunes.apple.com/us/app/dinova-restaurant-marketplace/id829424959?mt=8&at=10l6Xd';
		}

		// prod / feature
		if (isProdHost) {
			if (isWeMovedPath) {
				return 'https://dinova.onelink.me/vg2y/WeMovedLP';
			} else if (isSubscribePath) {
				// if (shortLink) {
				// return `https://dinova.onelink.me/vg2y/${shortLink}`;
				// } else {
				return 'https://dinova.onelink.me/vg2y/RegistrationLP';
				// }
			} else if (isAutoEnrollPath) {
				return 'https://dinova.onelink.me/vg2y/AutoEnrollLP';
			}
		}

		// Dev env we-moved, download, subscribe page
		// p4
		if (host.includes('p4')) {
			return '/p4_qrCode.png';
		}

		// uat
		if (host.includes('apps.uat')) {
			return '/uat_qrCode.png';
		}

		// local
		return '/local_qrCode.png';
	}, [host, isDownloadPath, isProdHost, isWeMovedPath, isSubscribePath, isAutoEnrollPath /*shortLink*/]);

	return downloadLink;
};

export default useAppStoreDownloadLink;
