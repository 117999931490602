const isNodeEnv = env => {
	return process.env.NODE_ENV === env;
};

export const isDev = () => {
	return process.env.NODE_ENV === 'development';
};

export const isProd = () => {
	return process.env.NODE_ENV === 'prod' || process.env.NODE_ENV === 'production';
};

export const isUat = () => {
	return process.env.NODE_ENV === 'uat';
};

export const isLocal = () => {
	return (window?.location?.hostname || '') === 'dinova.local';
};

export default isNodeEnv;
