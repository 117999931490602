/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 2
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/dinova/Dining%20Guides/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/dinova/Dining%20Guides/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [configuration]
 * @property {BrowserClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'diningguides'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} diningguides
 */
export const ApiKey = {
  diningguides: '71b157dede36075ab0e9dcb55cf9410d'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration = {
  plan: {
    version: '2',
    branch: 'main',
    source: 'web',
    versionId: 'd2c9a1b2-6432-4ee5-893c-ded1f3b8ea42'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-javascript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export class CreateGuideScreenViewed {
  constructor(properties) {
    this.event_type = 'Create Guide Screen Viewed';
    this.event_properties = properties;
  }
}

export class DiningGuideCreated {
  constructor(properties) {
    this.event_type = 'Dining Guide Created';
    this.event_properties = properties;
  }
}

export class DiningGuideDirectoryViewed {
  constructor(properties) {
    this.event_type = 'Dining Guide Directory Viewed';
    this.event_properties = properties;
  }
}

export class DiningGuideDownloaded {
  constructor(properties) {
    this.event_type = 'Dining Guide Downloaded';
    this.event_properties = properties;
  }
}

export class DiningGuideEdited {
  constructor(properties) {
    this.event_type = 'Dining Guide Edited';
    this.event_properties = properties;
  }
}

export class DiningGuideShared {
  constructor(properties) {
    this.event_type = 'Dining Guide Shared';
    this.event_properties = properties;
  }
}

export class DiningGuideStarted {
  constructor(properties) {
    this.event_type = 'Dining Guide Started';
    this.event_properties = properties;
  }
}

export class DiningGuideViewed {
  constructor(properties) {
    this.event_type = 'Dining Guide Viewed';
    this.event_properties = properties;
  }
}

export class DiningGuidesDeleted {
  constructor(properties) {
    this.event_type = 'Dining Guides Deleted';
    this.event_properties = properties;
  }
}

export class EditDiningGuideStarted {
  constructor(properties) {
    this.event_type = 'Edit Dining Guide Started';
    this.event_properties = properties;
  }
}

export class EditScreenViewed {
  constructor(properties) {
    this.event_type = 'Edit Screen Viewed';
    this.event_properties = properties;
  }
}

export class NotificationsScreenViewed {
  constructor(properties) {
    this.event_type = 'Notifications Screen Viewed';
    this.event_properties = properties;
  }
}

export class RestaurantDetailsViewed {
  constructor(properties) {
    this.event_type = 'Restaurant Details Viewed';
    this.event_properties = properties;
  }
}

/**
 * @typedef PromiseResult
 * @type {object}
 * @property {Promise|undefined} promise
 */

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {BrowserClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
  }

  /**
   * @return {BrowserClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param {LoadOptions} options Configuration options to initialize the Ampli SDK with. 'environment', 'client.apiKey' or 'client.instance' is required.
   *
   * @return {PromiseResult}
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey;
    if (options?.client?.apiKey) {
      apiKey = options.client.apiKey;
    } else if (options?.environment) {
      apiKey = ApiKey[options.environment];
    }

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options?.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user.
   *
   * @param {string|undefined} userId The user's id.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  identify(userId, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options, user_id: userId};
    }

    const ampIdentify = new amplitude.Identify();
    return this.amplitude.identify(ampIdentify, options);
  }

  /**
  * Flush the event.
  */
  flush() {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.flush();
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  track(event, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.track(event, undefined, options);
  }

  /**
   * Create Guide Screen Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/Dining%20Guides/events/main/latest/Create%20Guide%20Screen%20Viewed)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.creator Username of the dining guide creator
   * @param {EventOptions} [options] Options for this track call.
   */
  createGuideScreenViewed(properties, options) {
    return this.track(new CreateGuideScreenViewed(properties), options);
  }

  /**
   * Dining Guide Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/Dining%20Guides/events/main/latest/Dining%20Guide%20Created)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.company Property has no description in tracking plan.
   * @param {string} properties.creator Username of the dining guide creator
   * @param {string} properties.dining_guide_name Property has no description in tracking plan.
   * @param {string} properties.location Property has no description in tracking plan.
   * @param {number} properties.number_of_restaurants Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  diningGuideCreated(properties, options) {
    return this.track(new DiningGuideCreated(properties), options);
  }

  /**
   * Dining Guide Directory Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/Dining%20Guides/events/main/latest/Dining%20Guide%20Directory%20Viewed)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.company Property has no description in tracking plan.
   * @param {number} properties.number_of_dining_guides Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  diningGuideDirectoryViewed(properties, options) {
    return this.track(new DiningGuideDirectoryViewed(properties), options);
  }

  /**
   * Dining Guide Downloaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/Dining%20Guides/events/main/latest/Dining%20Guide%20Downloaded)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.company Property has no description in tracking plan.
   * @param {string} properties.dining_guide_name Property has no description in tracking plan.
   * @param {string} properties.location Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  diningGuideDownloaded(properties, options) {
    return this.track(new DiningGuideDownloaded(properties), options);
  }

  /**
   * Dining Guide Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/Dining%20Guides/events/main/latest/Dining%20Guide%20Edited)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.company Property has no description in tracking plan.
   * @param {string} properties.dining_guide_name Property has no description in tracking plan.
   * @param {string} properties.location Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  diningGuideEdited(properties, options) {
    return this.track(new DiningGuideEdited(properties), options);
  }

  /**
   * Dining Guide Shared
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/Dining%20Guides/events/main/latest/Dining%20Guide%20Shared)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.company Property has no description in tracking plan.
   * @param {string} properties.dining_guide_name Property has no description in tracking plan.
   * @param {string} properties.location Property has no description in tracking plan.
   * @param {string} properties.url_copied Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  diningGuideShared(properties, options) {
    return this.track(new DiningGuideShared(properties), options);
  }

  /**
   * Dining Guide Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/Dining%20Guides/events/main/latest/Dining%20Guide%20Started)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.company Property has no description in tracking plan.
   * @param {string} properties.creator Username of the dining guide creator
   * @param {string} properties.dining_guide_name Property has no description in tracking plan.
   * @param {string} properties.location Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  diningGuideStarted(properties, options) {
    return this.track(new DiningGuideStarted(properties), options);
  }

  /**
   * Dining Guide Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/Dining%20Guides/events/main/latest/Dining%20Guide%20Viewed)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.company Property has no description in tracking plan.
   * @param {string} properties.location Property has no description in tracking plan.
   * @param {number} properties.number_of_restaurants Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  diningGuideViewed(properties, options) {
    return this.track(new DiningGuideViewed(properties), options);
  }

  /**
   * Dining Guides Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/Dining%20Guides/events/main/latest/Dining%20Guides%20Deleted)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.company Property has no description in tracking plan.
   * @param {string} properties.creator Username of the dining guide creator
   * @param {string} properties.dining_guide_name Property has no description in tracking plan.
   * @param {string} properties.location Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  diningGuidesDeleted(properties, options) {
    return this.track(new DiningGuidesDeleted(properties), options);
  }

  /**
   * Edit Dining Guide Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/Dining%20Guides/events/main/latest/Edit%20Dining%20Guide%20Started)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.company Property has no description in tracking plan.
   * @param {string} properties.creator Username of the dining guide creator
   * @param {string} properties.dining_guide_name Property has no description in tracking plan.
   * @param {string} properties.location Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  editDiningGuideStarted(properties, options) {
    return this.track(new EditDiningGuideStarted(properties), options);
  }

  /**
   * Edit Screen Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/Dining%20Guides/events/main/latest/Edit%20Screen%20Viewed)
   *
   * creator = (admin) viewing the page
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.creator Username of the dining guide creator
   * @param {EventOptions} [options] Options for this track call.
   */
  editScreenViewed(properties, options) {
    return this.track(new EditScreenViewed(properties), options);
  }

  /**
   * Notifications Screen Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/Dining%20Guides/events/main/latest/Notifications%20Screen%20Viewed)
   *
   * creator = (admin) user logged in
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.creator Username of the dining guide creator
   * @param {EventOptions} [options] Options for this track call.
   */
  notificationsScreenViewed(properties, options) {
    return this.track(new NotificationsScreenViewed(properties), options);
  }

  /**
   * Restaurant Details Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/dinova/Dining%20Guides/events/main/latest/Restaurant%20Details%20Viewed)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.restaurant_brand Property has no description in tracking plan.
   * @param {string} properties.restaurant_id Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  restaurantDetailsViewed(properties, options) {
    return this.track(new RestaurantDetailsViewed(properties), options);
  }
}

export const ampli = new Ampli();
