import * as t from './types';
// import * as fetchTypes from 'actions/requests/types';
import * as profileSetup from 'actions/profile_setup/types';
import { basePath, AUTH_REQUEST_HEADER } from 'actions/utils';
import axios from 'axios';
import { setError } from '../registration';
import { setIsLoading, setError as setLoaderError } from '../loader';
import moment from 'moment';
import Cookies from 'universal-cookie';
import setSecureCookie from 'utils/secure_cookie';
import { shouldDoTimeInMinsDependantRequest } from 'utils/requests';
import Promise from 'bluebird';
import { isIE } from 'utils/browser';
import { QueryClient } from '@tanstack/react-query';
import { USER_QUERY_KEY } from 'reactQuery/queries';
import { selectCompany } from 'actions/company_select';
import * as amplitude from '@amplitude/analytics-browser';

// const cookies = new Cookies();

/**
 * Clear any errors.
 */
export const clearError = () => ({
	type: t.CLEAR_ERROR
});

// TODO: Remove this func and all of user data state from redux once
// react-query is implemented everywhere
export const setUser = userData => ({
	type: t.SET_USER,
	payload: userData
});

export const identifyEvent = new amplitude.Identify();

export const getProfile = (showLoading = true, forceFetch = false) => async (dispatch, getState) => {
	const URL = `${basePath()}/users`;
	let doFetch;
	if (forceFetch || isIE()) {
		doFetch = true;
	} else {
		doFetch = shouldDoTimeInMinsDependantRequest(URL, 5, getState);
	}

	if (!doFetch) {
		return getState().users.user;
	} else {
		let cookies = new Cookies();
		if (showLoading) {
			// console.log('%c ****getProfile - starting the loader****', 'color:blue');
			dispatch(setIsLoading(true, 'Fetching user details'));
		}

		return axios
			.get(URL, AUTH_REQUEST_HEADER())
			.then(async response => {
				// if (hasProp(response, 'data.user.company.code')) {
				// 	await dispatch(getConnectionType(response.data.user.company.code, showLoading));
				// }
				// dispatch({
				// 	type: fetchTypes.FETCH_SUCCESS,
				// 	payload: URL
				// });
				dispatch({
					type: t.SET_USER,
					payload: response.data.user
				});
				dispatch({
					type: profileSetup.SET_COMPLETED_STEPS,
					payload: Object.keys(response.data.user.onboardingStatus).filter(
						key => response.data.user.onboardingStatus[key] === true
					).length
				});
				// console.log('%c ****getProfile - Stopping the loader****', 'color:blue');
				if (getState().loader.isBusy && getState().loader.message === 'Fetching user details') {
					dispatch(setIsLoading(false));
				}
				if (response.data.user.onboardingStatus.stopDate !== null) {
					dispatch({
						type: profileSetup.DISMISS_FOREVER
					});
				}

				cookies.set('profile_setup_dismissed_forever', response.data.user.onboardingStatus.stopDate !== null);

				return response.data.user;
			})
			.catch(async e => {
				// dispatch({
				// 	type: fetchTypes.FETCH_ERROR,
				// 	payload: URL
				// });
				if (e.response) {
					dispatch({
						type: t.FETCH_USER_ERROR,
						payload: e.response.data.response.code
					});
					// console.log(
					// 	'%c ****getProfile - in catch with response - Stopping the loader****',
					// 	'color:blue',
					// 	e.response.data
					// );
					if (getState().loader.isBusy && getState().loader.message === 'Fetching user details') {
						dispatch(setIsLoading(false));
					}
					return e.response.data.response.code;
				} else {
					cookies.remove('token', {
						path: '/',
						domain: getDomain(window.location.hostname, true)
					});
					cookies.remove('refresh', {
						path: '/',
						domain: getDomain(window.location.hostname, true)
					});
					cookies.remove('idToken', {
						path: '/',
						domain: getDomain(window.location.hostname, true)
					});
					await logout(dispatch);
					window.zupplerBridge && window.zupplerBridge.logout();
					dispatch({
						type: t.FETCH_USER_ERROR,
						payload: 500
					});
					// console.log(
					// 	'%c ****getProfile - in catch some other error - Stopping the loader****',
					// 	'color:blue'
					// );
					if (getState().loader.isBusy && getState().loader.message === 'Fetching user details') {
						dispatch(setIsLoading(false));
					}
					return 500;
				}
			});
	}
};

/**
 * Refresh the logged in user's token.
 * @param  {string} refreshToken The refresh token.
 */
export const refresh = () => async dispatch => {
	try {
		const cookies = new Cookies();
		const refreshToken = cookies.get('refresh');

		if (!refreshToken) {
			return;
		} else {
			dispatch(refreshStart());
			const response = await axios.post(
				`${basePath()}/users/authorize`,
				JSON.stringify({ refreshToken: refreshToken })
			);

			switch (response.data.response.code) {
				case 200:
					dispatch(refreshTime());
					setSecureCookie({
						name: 'token',
						data: response.data.tokens.access,
						path: '/',
						domain: getDomain(window.location.hostname, true)
					});
					dispatch({ type: t.REFRESH_USER, payload: response.data.tokens });
					break;
				case 408:
					clearAuth(dispatch);
					break;
				case 400:
					break;
				default:
					break;
			}
		}
	} catch (e) {
		switch (e.response.data.response.code) {
			case 400:
			case 408:
				clearAuth(dispatch);
				break;
			default:
				break;
		}
	} finally {
		dispatch(refreshStop());
	}
};

/**
 * Login a user.
 * @param  {object}   user     The user to log in.
 * @param  {string}   username The user's username.
 * @param  {string}   password The user's password.
 * @param  {Function} callback User login API res data handler.
 */
export const login = (user, callback, returnFullResponse) => async dispatch => {
	try {
		dispatch(setIsLoading(true, 'Please wait'));
		const response = await axios.post(`${basePath()}/users/authorize`, JSON.stringify(user));
		switch (response.data.response.code) {
			case 200:
				dispatch(refreshTime());
				dispatch({ type: t.AUTH_USER, payload: response.data.user.tokens });
				dispatch({ type: t.SET_USER, payload: response.data.user });
				dispatch({ type: t.USER_LAST_LOGIN, payload: response.data.user.lastLogin });

				setSecureCookie({
					name: 'token',
					data: response.data.user.tokens.access,
					path: '/',
					domain: getDomain(window.location.hostname, true)
				});
				setSecureCookie({
					name: 'refresh',
					data: response.data.user.tokens.refresh,
					path: '/',
					domain: getDomain(window.location.hostname, true)
				});
				setSecureCookie({
					name: 'idToken',
					data: response.data.user.tokens.id,
					path: '/',
					domain: getDomain(window.location.hostname, true)
				});
				dispatch({ type: t.DOORDASH_MODE, payload: response.data.user.isDoordashUser });
				break;
			case 410:
				dispatch({ type: t.RESET_PASSWORD, payload: response.data.user });
				break;
			case 411:
				response.data.response['mfaToken'] = response.data.mfaToken;
				response.data.response['sentTo'] = response.data.sentTo;
				// dispatch();
				break;
			case 412:
				dispatch({ type: t.MIGRATE_PASSWORD, payload: response.data.user });
				break;
			case 408:
				clearAuth(dispatch);
				break;
			default:
				dispatch({ type: t.AUTH_ERROR, payload: response.data.code });
				break;
		}

		callback(returnFullResponse ? response.data : response.data.response);
	} catch (e) {
		switch (e.response.data.response.code) {
			case 408:
				clearAuth(dispatch);
				break;
			case 410:
				dispatch({ type: t.RESET_PASSWORD, payload: e.response.data.user });
				callback(e.response.data.response);
				break;
			case 411:
				e.response.data.response['mfaToken'] = e.response.data.mfaToken;
				e.response.data.response['sentTo'] = e.response.data.sentTo;
				callback(e.response.data.response);
				break;
			case 412:
				dispatch({ type: t.MIGRATE_PASSWORD, payload: e.response.data.user });
				callback(e.response.data.response);
				break;
			default:
				break;
		}

		callback(e.response.data.response);

		if (e.response.data.response.code != 411) {
			dispatch({
				type: t.AUTH_ERROR,
				payload: e.response.data.response.code
			});
		}
	} finally {
		dispatch(setIsLoading(false));
	}
};

const clearAuth = dispatch => {
	let cookies = new Cookies();
	resetRefreshTime();
	cookies.remove('token', {
		path: '/',
		domain: getDomain(window.location.hostname, true)
	});
	cookies.remove('refresh', {
		path: '/',
		domain: getDomain(window.location.hostname, true)
	});
	cookies.remove('idToken', {
		path: '/',
		domain: getDomain(window.location.hostname, true)
	});

	if (dispatch) {
		dispatch({
			type: t.CLEAR_USER,
			payload: ''
		});
	}
};
/**
 * Logout the logged in user.
 */
export const logout = dispatch => {
	// dispatch({ type: fetchTypes.CLEAR_FETCH, payload: 'ALL' });

	return new Promise(resolve => {
		axios
			.get(`${basePath()}/users/logout`, AUTH_REQUEST_HEADER())
			.then(() => {
				clearAuth(dispatch);
			})
			.catch(() => {
				clearAuth(dispatch);
			})
			.finally(() => {
				let cookies = new Cookies();
				dispatch({ type: profileSetup.DEFAULT_ONBOARDING });
				dispatch({ type: t.DOORDASH_MODE, payload: cookies.get('doordash') === 'true' });
				const queryClient = new QueryClient();
				queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEY] });
				dispatch(selectCompany({ name: '' }));
				dispatch({ type: profileSetup.RESET });
				resolve();
			});
	});
};

export const update = (
	userDetails,
	callback,
	showLoading = true,
	updateUser = true,
	useUserResForUpdate = false
) => async dispatch => {
	try {
		if (showLoading) {
			dispatch(setIsLoading(true, 'updating user'));
		}
		// console.log('ud1:', userDetails);
		const response = await axios.patch(`${basePath()}/users`, JSON.stringify(userDetails), AUTH_REQUEST_HEADER());
		if (userDetails.diningTypes !== undefined) {
			userDetails.diningTypes = userDetails.diningTypes.map(obj => ({
				id: obj.id,
				type: obj.id === '1' ? 'Catering' : 'Private Dining'
			}));
		}
		// console.log('ud:', userDetails);
		// dispatch({
		// 	type: fetchTypes.CLEAR_FETCH,
		// 	payload: `${basePath()}/users`
		// });

		if (updateUser) {
			dispatch({
				type: t.UPDATE_USER,
				payload: useUserResForUpdate ? response.data.user : userDetails
			});
		}
		if (callback) {
			callback(response.data.response);
		}
	} catch (e) {
		// console.log('Error', e);
		if (e.response) {
			dispatch(setError(e.response.data.response.code));
			callback(e.response.data.response);
		} else {
			dispatch(setError(500));
		}
	} finally {
		if (showLoading) {
			// console.log('%c ****update - Stopping the loader****', 'color:blue');
			dispatch(setIsLoading(false));
		}
	}
};

/**
 * Unsubscribe a user.
 * @param  {object}   userDetails
 * @param  {string}   userDetails.usType
 * @param  {string}   userDetails.token
 * @param  {Function} callback    API res data handler.
 */
export const unsubscribe = (userDetails, callback) => async dispatch => {
	try {
		const response = await axios.post(`${basePath()}/users/unsubscribe`, JSON.stringify(userDetails));
		callback(response.data.response);
	} catch (e) {
		if (e.response) {
			dispatch(setError(e.response.data.response.code));
			callback(e.response.data.response);
		} else {
			dispatch(setError(500));
		}
	}
};

/**
 * Update a user's password.
 * @param  {object}   changedPassword
 * @param  {string}   changedPassword.old The previous password.
 * @param  {string}   changedPassword.new The new password.
 * @param  {Function} callback            Password reset API res data handler.
 */
export const changePassword = (changedPassword, callback) => async dispatch => {
	try {
		dispatch(setIsLoading(true, 'updating password'));

		const response = await axios.put(
			`${basePath()}/users/password`,
			JSON.stringify(changedPassword),
			AUTH_REQUEST_HEADER()
		);
		callback(response.data.response);
	} catch (e) {
		dispatch(setError(e.response.data.response.code));
		callback(e.response.data.response);
	} finally {
		dispatch(setIsLoading(false));
	}
};

/**
 * Add a credit card connection to a user.
 * @param  {object}        cardDetails
 * @param  {number}        cardDetails.id        The type of the credit card.
 * @param  {string}        cardDetails.firstName The first name of the card holder.
 * @param  {string}        cardDetails.lastName  The last name of the card holder.
 * @param  {string|number} cardDetails.number    The credit card number.
 * @param  {Function}      callback              Card connected API res data handler.
 */
export const connectToPoints = (cardDetails, callback) => async dispatch => {
	dispatch(setError());
	try {
		dispatch(setIsLoading(true, 'connecting user'));

		const response = await axios.post(
			`${basePath()}/users/connections`,
			JSON.stringify(cardDetails),
			AUTH_REQUEST_HEADER()
		);

		// dispatch({
		// 	type: fetchTypes.CLEAR_FETCH,
		// 	payload: `${basePath()}/users`
		// });

		dispatch({ type: t.USER_CREATED_CONNECTION });
		await callback(response.data.response);
	} catch (e) {
		if (e.response) {
			dispatch(setError(e.response.data.response.code));
			await callback(e.response.data.response.code);
		} else {
			dispatch(setError(500));
			await callback(500);
			window.onerror(JSON.stringify(e), 'src/actions/users', 415);
		}
	} finally {
		dispatch(setIsLoading(false));
	}
};

/**
 * Update user payment info.
 */
export const updatePaymentInfo = (data, callback) => async dispatch => {
	try {
		dispatch(setIsLoading(true, 'updating payment information'));

		const response = await axios.patch(
			`${basePath()}/users/connections/${data.connectionID}`,
			JSON.stringify(data.payment),
			AUTH_REQUEST_HEADER()
		);
		callback(response.data.response);
	} catch (e) {
		if (e.response) {
			dispatch(setLoaderError(e.response.data.response.code));
			callback(e.response.data.response.code);
		} else {
			dispatch(setLoaderError(500));
		}
	} finally {
		dispatch(setIsLoading(false));
	}
};

/**
 * Trigger forgotPassword email flow for user.
 */
export const forgotPassword = (user, callback) => async dispatch => {
	try {
		dispatch(setIsLoading(true, 'requesting password'));
		const response = await axios.post(`${basePath()}/users/forgotpassword`, JSON.stringify(user));
		if (user.token) {
			dispatch({ type: t.AUTH_USER, payload: '' });
		}
		callback(response);
	} catch (e) {
		if (e.response) {
			dispatch(setLoaderError(e.response.data.response.code));
		} else {
			dispatch(setLoaderError(500));
		}
	} finally {
		dispatch(setIsLoading(false));
	}
};
export const updateOnboardingStatus = status => {
	console.log('status', status);
	if (status.onboardingStatus.completeProfile) {
		return { type: t.USER_COMPLETED_PROFILE, status };
	}
	if (status.onboardingStatus.optInNotification === true) {
		return { type: t.USER_COMPLETED_EMAIL_OPT_IN_OUT, status };
	}
	if (status.onboardingStatus.optInNotification === false) {
		return { type: t.USER_COMPLETED_EMAIL_OPT_IN_OUT, status };
	}
	if (status.onboardingStatus.favoriteRestaurant) {
		return { type: t.USER_FAVORITED_RESTAURANT, status };
	}
	if (status.onboardingStatus.finishOnboarding) {
		return { type: t.USER_CLOSED_FINISH, status };
	}
};

/**
 * Registration success handler
 */
export const registerSuccess = payload => {
	return { type: t.REGISTER_SUCCESS, payload };
};

/**
 * Registration failure handler
 */
export const registerFailure = () => {
	return { type: t.REGISTER_FAILURE };
};

/**
 * User logged in refresh start.
 */
export const refreshStart = () => {
	return { type: t.REFRESH_USER_RUNNING, payload: true };
};

/**
 * User logged in refresh stop.
 */
export const refreshStop = () => {
	return { type: t.REFRESH_USER_RUNNING, payload: false };
};

/**
 * User logged in refresh time.
 */
export const refreshTime = () => {
	return { type: t.REFRESH_TIME, payload: moment() };
};

/**
 * User logged in reset time.
 */
export const resetRefreshTime = () => {
	return { type: t.REFRESH_TIME, payload: null };
};

const getDomain = (url, subdomain) => {
	subdomain = subdomain || false;

	url = url.replace(/(https?:\/\/)?(www.)?/i, '');

	if (!subdomain) {
		url = url.split('.');

		url = url.slice(url.length - 2).join('.');
	} else {
		let arr = url.split('.');
		if (arr.length == 4) {
			url = arr.slice(arr.length - 3).join('.');
		}
	}

	if (url.indexOf('/') !== -1) {
		return url.split('/')[0];
	}

	return url;
};
