import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import MetaTags from './MetaTags';
import SmartBanner from 'react-smartbanner';
import Cookies from 'universal-cookie';
import IdleTimer from 'react-idle-timer';
import queryString from 'query-string';
import ScrollToTop from 'utils/scrollToTop';
import LazyHelpFAQ from 'components/ecosystems/HelpFAQ/lazy-container.js';
import {
	ProfileSetupModal,
	CompanySelectModal,
	DeleteConnectionsWarningModal,
	RecommendationsModal,
	PointsElegibilityModal
} from 'components/organisms';
import { LastLocationProvider } from 'react-router-last-location';
import { Loader } from 'components/atoms';
import classnames from 'classnames';
import { isIE, isFireFox, isEdge, isMobile, isSafari } from 'utils/browser';
// import { Completion } from 'components/molecules';
import { PERMISSIONS } from 'utils/permissions';
import { Toaster } from 'react-hot-toast';
import { GeneralContextConsumer, GeneralContextProvider } from 'context/GeneralContext';
import { BASE_URL as AdminDiningGuidesBaseUrl } from 'components/ecosystems/AdminDiningGuidesPage/constants';
import {
	// LazyAnonDashboardPage,
	LazyAdminDiningGuidesPage,
	LazyAutoEnrollAdminPage,
	LazyAutoEnrollUserPage,
	LazyComingSoonBanner,
	LazyCompanyDashboard,
	// LazyDashboardPage,
	LazyDetailsPage,
	LazyDiningGuidesPage,
	LazyDiningGuidePage,
	// LazyEditPaymentPage,
	// LazyEditPersonalPage,
	// LazyEditPreferencesPage,
	// LazyEditSecurity,
	// LazyExpired,
	// LazyFavoritesPage,
	LazyFooter,
	LazyForgottenPage,
	LazyHeader,
	LazyLandingPage,
	LazyLoginPage,
	LazyLogoutPage,
	// LazyNoResponsePage,
	LazyComingSoonPage,
	// LazyNoResponsePage,
	LazyNotFoundPage,
	LazyOnboardingAdminPage,
	//LazyOrderPage,
	//LazyOrderReceiptPage,
	LazyPermissionsRoute,
	// LazyPrivateRoute,
	// LazyProfilePage,
	// LazyRecommendationsPage,
	LazyRedirectToAppStore,
	LazyReferPage,
	LazyResetPage,
	// LazyResetPasswordPage,
	LazyResetRoute,
	// LazyRewardErrorPage,
	// LazyRewardsPage,
	// LazyRewardViewPage,
	LazyRoster,
	LazySearchPage,
	LazySubscribePage,
	LazyTermsPage
	// LazyTransactionsPage,
	// LazyUnsubscribePage
} from 'components/ecosystems';
import { history } from 'utils/router';
import { AuthContextProvider } from 'context/AuthContext';
import { UserContextConsumer, UserContextProvider } from 'context/UserContext';
import { AmplitudeContextProvider } from 'context/AmplitudeContext';

class Layout extends Component {
	constructor(props) {
		super(props);
		this.idleTimer = null;
		this.state = {
			timedOut: false,
			banner: {
				title: this.props.translate('Banner.title'),
				author: this.props.translate('Banner.author'),
				price: {
					ios: this.props.translate('Banner.price.ios'),
					android: this.props.translate('Banner.price.android'),
					windows: this.props.translate('Banner.price.windows'),
					kindle: this.props.translate('Banner.price.kindle')
				},
				button: this.props.translate('Banner.button'),
				storeText: {
					ios: this.props.translate('Banner.storeText.ios'),
					android: this.props.translate('Banner.storeText.android'),
					windows: this.props.translate('Banner.storeText.windows'),
					kindle: this.props.translate('Banner.storeText.kindle')
				},
				icon: `${props.IMAGE_BASE_URL}/logos/Dinova-favicon.png`
			}
		};
	}

	cookies = new Cookies();

	componentDidMount = async () => {
		queryString.parse(window.location.search).applicationAuth && this.props.setAndroid(true);
		queryString.parse(window.location.search).visa && this.props.setMinimalLayout(true);
		queryString.parse(window.location.search).network === 'visaedge' && this.props.setMinimalLayout(true);
		this.cookies.get('visaedge') && this.props.setMinimalLayout(true);
	};

	componentDidUpdate() {
		this.idleTimer && this.idleTimer.reset();
	}

	onClick = () => {
		if (this.props.mobileNavOpen) {
			this.props.toggle();
		}
	};

	onIdle = isAuthenticated => () => {
		if (isAuthenticated) {
			history.replace('/logout');
		}
	};

	reset = () => {
		this.idleTimer.reset();
	};

	render() {
		const { androidVersion, minimalLayout, isBusy, loadingMessage, translate } = this.props;

		const isAdminDiningGuidesPage = history.location.pathname.includes(AdminDiningGuidesBaseUrl);
		const noFooterPage = androidVersion || isAdminDiningGuidesPage;

		const classProps = classnames(
			'app-wrapper',
			isIE() && 'ie',
			isFireFox() && 'ff',
			isEdge() && 'edge',
			isMobile() && 'mobile',
			isSafari() && 'safari'
		);

		return (
			<Router history={history}>
				<AmplitudeContextProvider>
					<LastLocationProvider>
						<AuthContextProvider>
							<UserContextProvider>
								<UserContextConsumer>
									{({ user }) => (
										<GeneralContextProvider>
											<ScrollToTop>
												{/* update meta tags */}
												<MetaTags />
												<GeneralContextConsumer>
													{({
														authState: { isAuthed: isAuthenticated },
														isDoorDashMode,
														isDoDMode
														// userQuery: { data: user }
													}) => {
														return (
															<>
																{!androidVersion &&
																!isDoorDashMode &&
																!isDoDMode &&
																!minimalLayout ? (
																	<SmartBanner {...this.state.banner} key={0} />
																) : null}
																<div onClick={this.onClick} className={classProps}>
																	<div className="app-content">
																		<Toaster
																			toastOptions={{
																				success: {
																					style: {
																						background: 'green',
																						color: 'white'
																					}
																				},
																				error: {
																					style: {
																						background: 'red',
																						color: 'white'
																					}
																				}
																			}}
																		/>
																		<LazyComingSoonBanner />
																		<RecommendationsModal />
																		<PointsElegibilityModal />
																		<DeleteConnectionsWarningModal />
																		<CompanySelectModal />

																		{isBusy && (
																			<Loader
																				message={
																					loadingMessage || 'Please wait'
																				}
																			/>
																		)}
																		{!androidVersion && <LazyHeader />}

																		<ProfileSetupModal />
																		<Switch>
																			{/* <Redirect
																			from="/catering"
																			to="/?tab=catering"
																		/> */}
																			{/* <Route
																			exact
																			path="/mydinova"
																			component={
																				isAuthenticated
																					? LazyDashboardPage
																					: LazyAnonDashboardPage
																			}
																		/> */}
																			<Redirect from="/mydinova" to="/we-moved" />

																			<Route
																				exact
																				path="/we-moved"
																				component={LazyComingSoonPage}
																			/>
																			<Route
																				exact
																				path="/users/register/download"
																				component={LazyComingSoonPage}
																			/>
																			<Route
																				exact
																				path="/download"
																				component={LazyComingSoonPage}
																			/>

																			<Route
																				exact
																				path="/search"
																				render={({
																					match,
																					location,
																					history
																				}) => {
																					const parsed = queryString.parse(
																						location.search
																					);
																					if (parsed.network === 'mceasy') {
																						const cookies = new Cookies();
																						cookies.set('mceasy', true, {
																							maxAge: 2147483647
																						});
																						let query = new URLSearchParams(
																							location.search
																						);
																						query.delete('network');
																						history.replace({
																							search: query.toString()
																						});
																					}
																					if (parsed.network === 'visaedge') {
																						const cookies = new Cookies();
																						cookies.set('visaedge', true, {
																							maxAge: 2147483647
																						});
																						let query = new URLSearchParams(
																							location.search
																						);
																						query.delete('network');
																						history.replace({
																							search: query.toString()
																						});
																					}

																					return (
																						<LazySearchPage
																							match={match}
																							location={location}
																						/>
																					);
																				}}
																			/>
																			<Route
																				exact
																				path="/restaurants/:id/:restaurantName-:city-:state/"
																				render={({
																					match,
																					location,
																					history
																				}) => {
																					return (
																						<LazyDetailsPage
																							match={match}
																							location={location}
																							history={history}
																						/>
																					);
																				}}
																			/>
																			<Route
																				exact
																				path="/restaurants/:id/:restaurantName-:city-:state/about_us"
																				render={({
																					match,
																					location,
																					history
																				}) => {
																					return (
																						<LazyDetailsPage
																							match={match}
																							location={location}
																							history={history}
																						/>
																					);
																				}}
																			/>
																			<Route
																				exact
																				path="/restaurants/:id/:restaurantName-:city-:state/catering"
																				render={({
																					match,
																					location,
																					history
																				}) => {
																					return (
																						<LazyDetailsPage
																							match={match}
																							location={location}
																							history={history}
																						/>
																					);
																				}}
																			/>
																			<Route
																				path="/restaurants/:id/:restaurantName-:city-:state/private_dining"
																				render={({
																					match,
																					location,
																					history
																				}) => {
																					return (
																						<LazyDetailsPage
																							match={match}
																							location={location}
																							history={history}
																						/>
																					);
																				}}
																			/>
																			<Redirect
																				from="/unable-to-connect"
																				to="/we-moved"
																			/>
																			{/* <Route
																			exact
																			path="/unable-to-connect"
																			component={LazyNoResponsePage}
																		/> */}
																			<Route
																				path="/admin" // new login page
																				component={LazyLoginPage}
																				exact
																			/>
																			<Redirect from="/login" to="/we-moved" />
																			{/* <Route
																			path="/login"
																			component={LazyLoginPage}
																			exact
																		/> */}
																			<Route
																				path="/logout"
																				component={LazyLogoutPage}
																			/>
																			<Route
																				path="/forgotten"
																				component={LazyForgottenPage}
																				exact
																			/>
																			<Route
																				path="/reset"
																				component={LazyResetPage}
																				exact
																			/>
																			<Route
																				path="/users/register"
																				component={LazySubscribePage}
																				exact
																			/>
																			<Redirect
																				from="/users/connect"
																				to="/we-moved"
																			/>
																			{/* <Route
																			path="/users/connect"
																			component={LazySubscribePage}
																			exact
																		/> */}
																			<Route
																				path="/download_mydinova"
																				component={LazyRedirectToAppStore}
																				exact
																			/>

																			<LazyResetRoute
																				path="/change"
																				component={LazyResetPage}
																			/>
																			<Route
																				path="/helpfaq"
																				exact
																				component={LazyHelpFAQ}
																			/>
																			<Route
																				path={'/helpfaq/business'}
																				component={LazyHelpFAQ}
																				exact
																			/>
																			<Route
																				path={'/helpfaq/catering'}
																				component={LazyHelpFAQ}
																				exact
																			/>
																			<Route
																				path={'/helpfaq/rewards/azn'}
																				component={LazyHelpFAQ}
																				exact
																			/>
																			<Route
																				path={'/helpfaq/rewards'}
																				component={LazyHelpFAQ}
																				exact
																			/>
																			<Route
																				path={'/helpfaq/dod'}
																				component={LazyHelpFAQ}
																				exact
																			/>
																			<Route
																				path={'/helpfaq/doordash'}
																				component={LazyHelpFAQ}
																				exact
																			/>
																			<Route
																				path="/terms"
																				component={LazyTermsPage}
																				exact
																			/>
																			<Redirect
																				from="/resetpassword"
																				to="/we-moved"
																			/>
																			{/* <Route
																			path="/resetpassword"
																			component={LazyResetPasswordPage}
																			exact
																		/> */}
																			<Redirect from="/expired" to="/we-moved" />
																			{/* <Route
																			path="/expired"
																			component={LazyExpired}
																			exact
																		/> */}
																			<LazyPermissionsRoute
																				requiredPermissions={[
																					PERMISSIONS.ADMIN
																				]}
																				path="/admin/auto-enroll"
																				component={LazyAutoEnrollAdminPage}
																				exact
																			/>
																			<Route
																				path="/users/register/auto-enroll"
																				component={LazyAutoEnrollUserPage}
																				exact
																			/>
																			<LazyPermissionsRoute
																				requireAuth
																				requiredPermissions={[
																					PERMISSIONS.ADMIN
																				]}
																				path="/admin/dashboard"
																				component={LazyCompanyDashboard}
																			/>
																			<LazyPermissionsRoute
																				requireAuth
																				requiredPermissions={[
																					PERMISSIONS.ADMIN
																				]}
																				path="/admin/dining-guides"
																				component={LazyAdminDiningGuidesPage}
																			/>
																			{/* <Route
																			path="/admin/dining-guides"
																			component={LazyAdminDiningGuidesPage}
																		/> */}
																			<Route
																				exact
																				path="/dining-guides/:companyCode"
																				component={LazyDiningGuidesPage}
																			/>
																			<Route
																				exact
																				path="/dining-guides/:companyCode/:diningGuideId"
																				component={LazyDiningGuidePage}
																			/>
																			{/* <LazyPermissionsRoute
																			// permissions={[PERMISSIONS.ADMIN]}
																			path="/admin/dining-guides"
																			component={LazyAdminDiningGuidesPage}
																		/> */}
																			<Redirect
																				from="/users/register/complete"
																				to="/we-moved"
																			/>
																			{/* <Route
																			path="/users/register/complete"
																			component={Completion}
																			exact
																		/> */}

																			<Route
																				path="/refer"
																				component={LazyReferPage}
																				exact
																			/>

																			<Redirect
																				from="/favorites"
																				to="/we-moved"
																			/>
																			{/* <LazyPrivateRoute
																			path="/favorites"
																			component={LazyFavoritesPage}
																			exact
																		/> */}
																			{/* <Redirect from="/subscribe" to="/we-moved" /> */}
																			{/* <Route
																			// Subscribe to myDionva rewards - not the same as unsubscribe from emails
																			path="/subscribe"
																			component={LazySubscribePage}
																			exact
																		/> */}
																			{/* // Unsubscribe from emails - not the same as subscribe to myDinova rewards */}
																			{/* <Redirect
																			from="/unsubscribe"
																			to="/we-moved"
																		/> */}
																			{/* <Route
																			path="/unsubscribe"
																			component={LazyUnsubscribePage}
																			exact
																		/> */}

																			<Redirect
																				from="/refer/details"
																				to="/we-moved"
																			/>
																			<Redirect
																				from="/recommendations"
																				to="/we-moved"
																			/>
																			{/* <LazyPrivateRoute
																			path="/refer/details"
																			component={LazyReferPage}
																			exact
																		/>
																		<LazyPrivateRoute
																			path="/recommendations"
																			component={LazyRecommendationsPage}
																			exact
																		/> */}
																			<Route
																				path="/roster"
																				component={LazyRoster}
																				exact
																			/>
																			<Redirect from="/rewards" to="/we-moved" />
																			<Redirect
																				from="/rewards/error"
																				to="/we-moved"
																			/>
																			<Redirect
																				from="/rewards/order/:id"
																				to="/we-moved"
																			/>
																			<Redirect
																				from="/rewards/receipt"
																				to="/we-moved"
																			/>
																			<Redirect
																				from="/rewards/view:id"
																				to="/we-moved"
																			/>
																			<Redirect from="/history" to="/we-moved" />
																			<Redirect from="/profile" to="/we-moved" />
																			<Redirect
																				from="/profile/personal"
																				to="/we-moved"
																			/>
																			<Redirect
																				from="/profile/payment"
																				to="/we-moved"
																			/>
																			<Redirect
																				from="/profile/preferences"
																				to="/we-moved"
																			/>
																			<Redirect
																				from="/profile/security"
																				to="/we-moved"
																			/>
																			{/* <LazyPrivateRoute
																			path="/rewards"
																			component={LazyRewardsPage}
																			exact
																		/>
																		<LazyPrivateRoute
																			path="/rewards/error"
																			component={LazyRewardErrorPage}
																		/>
																		<LazyPrivateRoute
																			path="/rewards/order/:id"
																			component={LazyOrderPage}
																		/>
																		<LazyPrivateRoute
																			path="/rewards/receipt"
																			component={LazyOrderReceiptPage}
																		/>
																		<LazyPrivateRoute
																			path="/rewards/view/:id"
																			component={LazyRewardViewPage}
																		/>
																		<LazyPrivateRoute
																			path="/history"
																			component={LazyTransactionsPage}
																			exact
																		/>
																		<LazyPrivateRoute
																			path="/profile"
																			component={LazyProfilePage}
																			exact
																		/>
																		<LazyPrivateRoute
																			path="/profile/personal"
																			component={LazyEditPersonalPage}
																		/>
																		<LazyPrivateRoute
																			path="/profile/payment"
																			component={LazyEditPaymentPage}
																		/>
																		<LazyPrivateRoute
																			path="/profile/preferences"
																			component={LazyEditPreferencesPage}
																		/>
																		<LazyPrivateRoute
																			path="/profile/security"
																			component={LazyEditSecurity}
																		/> */}
																			<Route
																				path="/admin/onboarding"
																				component={LazyOnboardingAdminPage}
																				exact
																			/>
																			<Route
																				exact
																				path="/"
																				render={({ location }) => {
																					// redirect to search page
																					const parsed = queryString.parse(
																						location.search
																					);
																					if (parsed.network === 'mceasy') {
																						const cookies = new Cookies();
																						cookies.set('mceasy', true, {
																							maxAge: 2147483647
																						});
																					}
																					if (parsed.network === 'visaedge') {
																						const cookies = new Cookies();
																						cookies.set('visaedge', true, {
																							maxAge: 2147483647
																						});
																					}
																					if (
																						parsed.location ||
																						parsed.find ||
																						parsed.near ||
																						parsed.keywords
																					) {
																						return (
																							<Redirect
																								to={`/search${location.search}`}
																							/>
																						);
																					}

																					return <LazyLandingPage />;
																				}}
																			/>
																			<Route component={LazyNotFoundPage} />
																		</Switch>
																	</div>
																	{!noFooterPage && <LazyFooter />}

																	{user && (
																		<IdleTimer
																			key={'idle-timer'}
																			ref={ref => {
																				this.idleTimer = ref;
																			}}
																			element={document}
																			onActive={this.onActive}
																			onIdle={this.onIdle(isAuthenticated)}
																			timeout={
																				1000 *
																				60 *
																				Number.parseInt(
																					translate('common.timeoutInMinutes')
																				)
																			} // 15 minutes
																			startOnLoad
																			reset={this.reset}
																		/>
																	)}
																</div>
															</>
														);
													}}
												</GeneralContextConsumer>
											</ScrollToTop>
										</GeneralContextProvider>
									)}
								</UserContextConsumer>
							</UserContextProvider>
						</AuthContextProvider>
					</LastLocationProvider>
				</AmplitudeContextProvider>
			</Router>
		);
	}
}

Layout.propTypes = {
	androidVersion: PropTypes.bool,
	minimalLayout: PropTypes.bool,
	isBusy: PropTypes.bool.isRequired,
	loadingMessage: PropTypes.string,
	mobileNavOpen: PropTypes.bool.isRequired,
	user: PropTypes.object,
	toggle: PropTypes.func,
	setAndroid: PropTypes.func,
	setMinimalLayout: PropTypes.func,
	translate: PropTypes.func,
	location: PropTypes.object,
	IMAGE_BASE_URL: PropTypes.string
};

export default Layout;
