import { useCallback, useEffect, useMemo } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { ampli as IosWebAmpliDev } from 'ampli/dev_ios_web';
import { ampli as IosWebAmpliProd } from 'ampli/prod_ios_web';
import { ampli as DiningGuidesAmpliDev } from 'ampli/dining_guides_dev';
import { ampli as DiningGuidesAmpliProd } from 'ampli/dining_guides_prod';
import { useLocation } from 'react-router';
import useUser from './useUser';
import { isProd, isUat } from 'utils/isNodeEnv';

let DiningGuidesAmpli;
let IosWebAmpli;
if (isProd() || isUat()) {
	DiningGuidesAmpli = DiningGuidesAmpliProd;
	IosWebAmpli = IosWebAmpliProd;
} else {
	DiningGuidesAmpli = DiningGuidesAmpliDev;
	IosWebAmpli = IosWebAmpliDev;
}

const diningGuideBaseUrls = ['/admin/dining-guides', '/dining-guide'];

const sessionReplayTracking = sessionReplayPlugin({
	sampleRate: 1.0
});

const ampliConfig = {
	autocapture: {
		attribution: true,
		pageViews: true,
		sessions: true,
		formInteractions: true,
		fileDownloads: true,
		elementInteractions: true
	}
};
/**
 * The PM Team wants to be able to track analytics for the dining guides pages
 * separately from the rest of the dinova ios/web product.  To do this we will
 * instantiate two instances of amplitude.
 *
 * This hook will create both instances as well as flush them on render cycle.
 *
 * Finally, the hook will return both instances so the rest of the app can
 * access them to send analytics as needed.
 *
 * AMPLI CONFIGS
 * You can find the ampli configs in `/amplitude_configs`.  Each project has its
 * own dir and ampli.json file.  To update any of the existing configs, in your terminal
 * `cd` to the required dir and run `ampli configure`.  You can then modify the branch
 * and or language. To update the path, just update the json file manually then run `ampli pull`.
 *
 * To CREATE A NEW config, add a dir in `/amplitude_configs`.  Make sure the name
 * of the dir matches the name of the project (replace spaces and special chars with `_`. Ex: 'Dining Guides (Dev) -> dining_guides_dev).
 * From that dir run `ampli init` and follow the prompts.
 *
 * NOTE: The current path for all ampli configs is `src/ampli/<project_name>`.  You will need to
 * set that upon config creation.
 */
const useAmplitudeState = () => {
	const { user } = useUser();
	const location = useLocation();
	const isDiningGuideUrl = useMemo(() => {
		const urlMatch = diningGuideBaseUrls.find(url => location.pathname.startsWith(url));
		return Boolean(urlMatch);
	}, [location]);

	// Amplitude instances
	// const iosWebAmpli = useMemo(() => amplitude.createInstance(), []);
	// const diningGuidesAmpli = useMemo(() => amplitude.createInstance(), []);

	// const ampliInstance = useMemo(() => {
	// 	const instance = amplitude.createInstance();
	// 	instance.add(sessionReplayTracking);
	// 	return instance;
	// }, []);

	const loadIosWebAmpli = useCallback(() => {
		const iosWebApiKey = process.env.REACT_APP_IOS_WEB_AMPLITUDE_API_KEY;
		// ampliInstance.init(iosWebApiKey, ampliConfig);
		IosWebAmpli.load({ ...ampliConfig, client: { apiKey: iosWebApiKey } });
		IosWebAmpli.amplitude.add(sessionReplayTracking);
		console.log('%c init success - iosWebAmpli');
	}, []);

	const loadDiningGuidesAmpli = useCallback(() => {
		const diningGuidesApiKey = process.env.REACT_APP_DINING_GUIDES_AMPLITUDE_API_KEY;
		// ampliInstance.init(diningGuidesApiKey, ampliConfig);
		DiningGuidesAmpli.load({ ...ampliConfig, client: { apiKey: diningGuidesApiKey } });
		DiningGuidesAmpli.amplitude.add(sessionReplayTracking);
		console.log('%c init success - diningGuidesAmpli');
	}, []);

	const identifyUser = useCallback(
		newUser => {
			const _user = newUser || user;
			const identifyEvent = new amplitude.Identify();

			const userFullName = `${_user.name.first} ${_user.name.last}`;
			identifyEvent.append('name', userFullName);
			identifyEvent.append('email', _user.email.primary);
			identifyEvent.append('company', _user.company.name);
			identifyEvent.append('companyCode', _user.company.code);
			identifyEvent.append('big_prize_campaign_code', Boolean(_user.bigPrizeCampaignCode));
			identifyEvent.append('highValueDiner', Boolean(_user.profile.highValueDiner));
			identifyEvent.append('traveler', Boolean(_user.profile.traveler));
			identifyEvent.append('meetingPlanner', Boolean(_user.profile.meetingPlanner));

			if (isDiningGuideUrl) {
				DiningGuidesAmpli.amplitude.setUserId(_user.id);
				DiningGuidesAmpli.amplitude.identify(identifyEvent);
			} else {
				IosWebAmpli.amplitude.setUserId(_user.id);
				IosWebAmpli.amplitude.identify(identifyEvent);
			}
		},
		[isDiningGuideUrl, user]
	);

	useEffect(() => {
		if (isDiningGuideUrl) {
			loadDiningGuidesAmpli();
		} else {
			loadIosWebAmpli();
		}

		return () => {
			IosWebAmpli.flush();
			DiningGuidesAmpli.flush();
		};
	}, [isDiningGuideUrl, loadIosWebAmpli, loadDiningGuidesAmpli]);

	useEffect(() => {
		if (user) {
			identifyUser();
		}
	}, [identifyUser, user]);

	/** Web IOS Events */
	const registrationCompleted = useCallback(
		newUser => {
			identifyUser(newUser);
			IosWebAmpli.registrationCompleted();
		},
		[identifyUser]
	);

	/** Dining Guide Events */

	// When the user creates a Dining Guide
	const diningGuideCreated = useCallback((diningGuide, userId) => {
		DiningGuidesAmpli.diningGuideCreated({
			company: diningGuide.clientCode || diningGuide.client.code,
			creator: userId,
			dining_guide_name: diningGuide.name,
			location: diningGuide.location,
			number_of_restaurants: (diningGuide.restaurants || []).length || 0
		});
	}, []);

	// When user lands on admin dining guides landing and clicks "Create Guide" tab.
	const diningGuideCreateScreenViewed = useCallback(userId => {
		DiningGuidesAmpli.createGuideScreenViewed({
			creator: userId
		});
	}, []);

	// When a dining guide is deleted
	const diningGuidesDeleted = useCallback((diningGuide, userId) => {
		DiningGuidesAmpli.diningGuidesDeleted({
			company: diningGuide.clientCode || diningGuide.client.code,
			creator: userId,
			location: diningGuide.location,
			dining_guide_name: diningGuide.name
		});
	}, []);

	// When the user views a company specific list of dining guides (non admin)
	const diningGuideDirectoryViewed = useCallback(({ company, number_of_dining_guides }) => {
		DiningGuidesAmpli.diningGuideDirectoryViewed({
			company,
			number_of_dining_guides
		});
	}, []);

	// When the user downloads a Dining Guide PDF
	const diningGuideDownloaded = useCallback(diningGuide => {
		DiningGuidesAmpli.diningGuideDownloaded({
			company: diningGuide.clientCode || diningGuide.client.code,
			dining_guide_name: diningGuide.name,
			location: diningGuide.location
		});
	}, []);

	// When a dining guide is edited
	const diningGuideEdited = useCallback(diningGuide => {
		DiningGuidesAmpli.diningGuideEdited({
			company: diningGuide.clientCode || diningGuide.client.code,
			dining_guide_name: diningGuide.name,
			location: diningGuide.location
		});
	}, []);

	// When the user lands on the home page and toggles to Edit (or is already on Edit)
	const diningGuideEditScreenViewed = useCallback(userId => {
		DiningGuidesAmpli.editScreenViewed({
			creator: userId
		});
	}, []);

	// When the user selects a guide from the edit select page
	const diningGuideEditStarted = useCallback((diningGuide, userId) => {
		DiningGuidesAmpli.editDiningGuideStarted({
			company: diningGuide.client.code,
			creator: userId,
			dining_guide_name: diningGuide.name,
			location: diningGuide.location
		});
	}, []);

	// When the admin dining guides notifications screen is viewed
	const diningGuidesNotificationsScreenViewed = useCallback(userId => {
		DiningGuidesAmpli.notificationsScreenViewed({
			creator: userId
		});
	}, []);

	// When a user navigates to restaurant details from a dining guide
	const diningGuidesRestaurantDetailsViewed = useCallback(restaurant => {
		DiningGuidesAmpli.restaurantDetailsViewed({
			restaurant_brand: restaurant.brand.name,
			restaurant_id: restaurant.brand.id
		});
	}, []);

	// When the user shares a Dining Guide URL
	const diningGuideShared = useCallback(diningGuide => {
		DiningGuidesAmpli.diningGuideShared({
			company: diningGuide.clientCode || diningGuide.client.code,
			dining_guide_name: diningGuide.name,
			location: diningGuide.location,
			url: window.location.href
		});
	}, []);

	// When the user clicks "Launch Guide Builder" button from admin dining guides create tab.
	const diningGuideStarted = useCallback((diningGuide, userId) => {
		DiningGuidesAmpli.diningGuideStarted({
			company: diningGuide.companyCode || diningGuide.company,
			creator: userId,
			dining_guide_name: diningGuide.name,
			location: diningGuide.location
		});
	}, []);

	// When a dining guide is viewed (non admin side)
	const diningGuideViewed = useCallback(diningGuide => {
		DiningGuidesAmpli.diningGuideViewed({
			company: diningGuide.clientCode || diningGuide.client.code,
			location: diningGuide.location,
			number_of_restaurants: (diningGuide.restaurants || []).length || 0
		});
	}, []);

	return {
		// iosWebAmpli,
		// diningGuidesAmpli,
		IosWebAmpli, // Class generated by ampli pull
		DiningGuidesAmpli, // Class generated by ampli pull
		ampliBroadcasters: {
			diningGuideCreated,
			diningGuideCreateScreenViewed,
			diningGuidesDeleted,
			diningGuideDirectoryViewed,
			diningGuideDownloaded,
			diningGuideEdited,
			diningGuideEditScreenViewed,
			diningGuideEditStarted,
			diningGuidesNotificationsScreenViewed,
			diningGuidesRestaurantDetailsViewed,
			diningGuideShared,
			diningGuideStarted,
			diningGuideViewed,
			registrationCompleted
		}
	};
};

export default useAmplitudeState;
