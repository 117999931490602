import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, NoResultsNotice, RestaurantListItemNew } from 'components/molecules';
import { chain } from 'lodash';
import classnames from 'classnames';
import styles from './style.module.scss';
// import "./style.scss";

/**
 * [RestaurantResultsList description]
 * @extends Component
 */
class RestaurantResultsList extends Component {
	sortByDistance = (restaurantA, restaurantB) => {
		const aDistance = restaurantA.distance?.span || restaurantA.distance || null;
		const bDistance = restaurantB.distance?.span || restaurantB.distance || null;
		return aDistance - bDistance;
	};
	/**
	 * Groups results by restaurant chain. Ex: Pizza Hut.
	 * @param  {[array]} restaurants An array of Restaurants returned from dinova api.
	 * @return {[array]}             The array of restaurants with sub arrays of grouped restaurants.  Ex: [Bob Evans, [Pizza Hut 1, Pizza Hut 2], McDonalds]
	 */
	groupedByRestaurants = (restaurants, shouldGroup) => {
		return shouldGroup
			? chain(restaurants)
					.groupBy(restaurant => restaurant?.brand?.id || restaurant.name)
					.map(locations => locations.sort(this.sortByDistance))
					.value()
			: chain(restaurants)
					.groupBy(restaurant => restaurant.id)
					.map(locations => locations.sort(this.sortByDistance))
					.value();
	};

	render() {
		const {
			activePin,
			didFetch,
			hideMapPin,
			isLoading,
			onItemClick,
			OnItemClickComponent,
			results,
			showFavoriteIcon,
			showFilter,
			shouldGroup = true,
			getIsItemSelected,
			variant,
			colGap,
			rowGap,
			listItemClass,
			detailsInNewTab
		} = this.props;

		const groupedResults = this.groupedByRestaurants(results, shouldGroup),
			hasResults = !isLoading || groupedResults.length > 0,
			noResults = didFetch && groupedResults.length === 0;

		return (
			<>
				{noResults && <NoResultsNotice />}
				{(hasResults || isLoading) && (
					<List
						className={classnames('business-search-results', this.props.listClassName, {
							[`${styles[variant]}`]: variant
						})}
						style={{
							gridColumnGap: colGap,
							gridRowGap: rowGap
						}}
						itemComponent={RestaurantListItemNew}
						itemComponentProps={{
							activePin,
							hideMapPin,
							onItemClick,
							showFilter,
							showFavoriteIcon,
							OnItemClickComponent,
							getIsItemSelected,
							listItemClass,
							detailsInNewTab
						}}
						items={isLoading ? [] : groupedResults}
						listId="search-results-list"
						isLoading={isLoading}
					/>
				)}
			</>
		);
	}
}

RestaurantResultsList.defaultProps = {};

/**
 * [propTypes description]
 * @interface RestaurantResultsListProps
 *
 * @example {}
 */
RestaurantResultsList.propTypes = {
	activePin: PropTypes.string,
	didFetch: PropTypes.bool.isRequired,
	hideMapPin: PropTypes.bool,
	isLoading: PropTypes.bool.isRequired,
	listClassName: PropTypes.string,
	onItemClick: PropTypes.func,
	OnItemClickComponent: PropTypes.node,
	getIsItemSelected: PropTypes.func,
	results: PropTypes.array.isRequired,
	showFilter: PropTypes.bool,
	showFavoriteIcon: PropTypes.bool,
	shouldGroup: PropTypes.bool,
	colGap: PropTypes.number,
	rowGap: PropTypes.number,
	variant: PropTypes.oneOf(['grid', 'list']),
	listItemClass: PropTypes.string,
	detailsInNewTab: PropTypes.bool
};

export default RestaurantResultsList;
